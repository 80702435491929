import React, { useEffect, useState } from "react";
import { fetch_Profile_Status } from "../api/userRegestration//CollectionForm";
import { NavLink, useNavigate } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";

import {
  AiOutlineHome,
  AiOutlineMail,
  AiFillLock,
  AiOutlineQuestionCircle,
} from "react-icons/ai";

import { IoChatbubblesSharp, IoWalletOutline } from "react-icons/io5";
import { HiOutlineLink } from "react-icons/hi";
import { TbLogout } from "react-icons/tb";
import { VscKey } from "react-icons/vsc";
import Logo from "../assets/logo/logo.jpg";
import { IoNewspaperOutline } from "react-icons/io5";
import { BsGraphUpArrow } from "react-icons/bs";
import MobileBottomNavbar from "../Widgets/MobileBottomNavbar";
import { useWallet } from "../pages/Wallet/Context/WalletContext";
const Sidebar = ({ toggleModal }) => {
  const { profiledata, fetch_Profile_Status } = useWallet();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const handleClick = (index) => {
    toggleSidebar();
    setActiveIndex(index);
  };

  const navigate = useNavigate();

  const handleClickd = () => {
    toggleModal();
    // Additional logic or actions
  };

  const navigate_profile_refresh_api = () => {
    fetch_Profile_Status();
    navigate("userProfile");
    // window.location.reload()
  };

  return (
    <>
      <div className="hidden  md:block lg:block">
        {/* Navigation Toggle */}
        <button
          type="button"
          className="text-gray-500 hover:text-gray-600"
          onClick={toggleSidebar}
          aria-label="Toggle navigation"
        >
          <span className="sr-only">Toggle Navigation</span>
          {isSidebarOpen ? (
            // Show close icon when sidebar is open
            <IoCloseOutline className="flex-shrink-0 w-6 h-6 text-red-900 absolute z-10" />
          ) : (
            // Show menu icon when sidebar is closed
            <svg
              className="flex-shrink-0 w-4 h-4"
              width={16}
              height={16}
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          )}
        </button>
        {/* End Navigation Toggle */}
        <div
          id="docs-sidebar"
          className={`hs-overlay ${
            isSidebarOpen
              ? "hs-overlay-open:translate-x-0"
              : "-translate-x-full"
          } transition-all duration-300 transform fixed top-0 start-0 bottom-0 z-[60] w-64 bg-white border-e border-gray-200  pb-2 overflow-y-auto lg:block lg:translate-x-0 lg:end-auto lg:bottom-0 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 shark:[&::-webkit-scrollbar-track]:bg-slate-700 shark:[&::-webkit-scrollbar-thumb]:bg-slate-500 shark:bg-gray-800 shark:border-gray-700 md:w-[15%] drop-shadow-md`}
        >
          <div
            className="cross-button text-red-400   float-right p-3 md:hidden block"
            onClick={toggleSidebar}
          >
            <IoCloseOutline />
          </div>

          <div className=" h-full px-3 py-4 overflow-y-auto  :bg-gray-800 ">
            <div className="logo flex items-center">
              <img src={Logo} className="h-[45px] mx-auto" alt="" />
            </div>

            {/*  */}

            {profiledata && (
              <>
                {/* <NavLink to="userProfile" > */}
                <div
                  className="user mt-4 mb-4 bg-[#cb22289b] flex flex-row items-center justify-center gap-3 h-[3rem] rounded-md cursor-pointer"
                  onClick={navigate_profile_refresh_api}
                >
                  <img
                    className=" w-12 h-12 rounded-full"
                    src={profiledata.profilePic}
                    alt="Rounded avatar"
                  />

                  <p className="font-all uppercase text-white font-semibold text-[13px]">
                    {profiledata.userName}
                  </p>
                </div>
                {/* </NavLink> */}
              </>
            )}

            {/*  */}

            <ul className=" space-y-2 font-medium">
              <NavLink to="/">
                {" "}
                <li
                  className={`${activeIndex === 0 ? "bg-nav" : ""}`}
                  onClick={() => handleClick(0)}
                >
                  <span className=" flex items-center p-2 text-gray-900 rounded-lg :text-white  hover:bg-[#f7dedf] cursor-pointer ">
                    <AiOutlineHome className="text-[17.5px]" />
                    <span className=" ml-3 font-all">Home</span>
                  </span>
                </li>
              </NavLink>

              {profiledata.referralCode ? (
                <>
                  <NavLink to="ResellerDashboard">
                    {" "}
                    <li
                      className={`${activeIndex === 1 ? "bg-nav" : ""}`}
                      onClick={() => handleClick(1)}
                    >
                      <span className=" flex items-center p-2 text-gray-900 rounded-lg :text-white  hover:bg-[#f7dedf] cursor-pointer ">
                        <BsGraphUpArrow className="text-[17.5px]" />
                        <span className=" ml-3 font-all">Reseller Dashboard</span>
                      </span>
                    </li>
                  </NavLink>
                </>
              ) : (
                <></>
              )}

              <NavLink to="chat">
                {" "}
                <li
                  className={`${activeIndex === 2 ? "bg-nav" : ""}`}
                  onClick={() => handleClick(2)}
                >
                  <span className=" flex items-center p-2 text-gray-900 rounded-lg :text-white  hover:bg-[#f7dedf] cursor-pointer ">
                    <IoChatbubblesSharp className="text-[17.5px]" />
                    <span className=" flex-1 ml-3 font-all whitespace-nowrap">
                      Chats
                    </span>
                  </span>
                </li>
              </NavLink>

              <NavLink to="wallet">
                {" "}
                <li
                  className={`${activeIndex === 3 ? "bg-nav" : ""}`}
                  onClick={() => handleClick(3)}
                >
                  <span className=" flex items-center p-2 text-gray-900 rounded-lg :text-white  hover:bg-[#f7dedf] cursor-pointer ">
                    <IoWalletOutline className="text-[17.5px]" />
                    <span className=" flex-1 ml-3 font-all whitespace-nowrap">
                      Wallet
                    </span>
                  </span>
                </li>
              </NavLink>

              <NavLink to="referal">
                {" "}
                <li
                  className={`${activeIndex === 4 ? "bg-nav" : ""}`}
                  onClick={() => handleClick(4)}
                >
                  <span className=" flex items-center p-2 text-gray-900 rounded-lg :text-white  hover:bg-[#f7dedf] cursor-pointer ">
                    <HiOutlineLink className="text-[17.5px]" />
                    <span className=" flex-1 ml-3 font-all whitespace-nowrap">
                      Referal
                    </span>
                  </span>
                </li>
              </NavLink>

              <NavLink to="contact-us">
                <li
                  className={`${activeIndex === 5 ? "bg-nav" : ""}`}
                  onClick={() => handleClick(5)}
                >
                  <span className=" flex items-center p-2 text-gray-900 rounded-lg :text-white  hover:bg-[#f7dedf] cursor-pointer ">
                    <AiOutlineMail className="text-[17.5px]" />
                    <span className=" flex-1 ml-3 font-all whitespace-nowrap">
                      Contact us
                    </span>
                  </span>
                </li>
              </NavLink>

              <NavLink to="TermsandService">
                {" "}
                <li
                  className={`${activeIndex === 6 ? "bg-nav" : ""}`}
                  onClick={() => handleClick(6)}
                >
                  <span className=" flex items-center p-2 text-gray-900 rounded-lg :text-white  hover:bg-[#f7dedf] cursor-pointer ">
                    <IoNewspaperOutline />
                    <span className=" flex-1 ml-3 font-all whitespace-nowrap">
                      Terms of Service
                    </span>
                  </span>
                </li>
              </NavLink>

              <NavLink to="Privacy&Policy">
                <li
                  className={`${activeIndex === 7 ? "bg-nav" : ""}`}
                  onClick={() => handleClick(7)}
                >
                  <span className=" flex items-center p-2 text-gray-900 rounded-lg :text-white  hover:bg-[#f7dedf] cursor-pointer ">
                    <AiFillLock className="text-[17.5px]" />
                    <span className=" flex-1 ml-3 font-all whitespace-nowrap">
                      Privacy Policy
                    </span>
                  </span>
                </li>
              </NavLink>

              <NavLink to="License">
                <li
                  className={`${activeIndex === 8 ? "bg-nav" : ""}`}
                  onClick={() => handleClick(8)}
                >
                  <span className=" flex items-center p-2 text-gray-900 rounded-lg :text-white  hover:bg-[#f7dedf] cursor-pointer ">
                    <VscKey className="text-[17.5px]" />
                    <span className=" flex-1 ml-3 font-all whitespace-nowrap">
                      Licenses
                    </span>
                  </span>
                </li>
              </NavLink>

              <NavLink to="Helpcenter">
                <li
                  className={`${activeIndex === 9 ? "bg-nav" : ""}`}
                  onClick={() => handleClick(9)}
                >
                  <span className=" flex items-center p-2 text-gray-900 rounded-lg :text-white  hover:bg-[#f7dedf] cursor-pointer ">
                    <AiOutlineQuestionCircle className="text-[17.5px]" />
                    <span className=" flex-1 ml-3 font-all whitespace-nowrap">
                      Help Center
                    </span>
                  </span>
                </li>
              </NavLink>

              <li
                className={`${activeIndex === 10 ? "bg-nav" : ""}`}
                onClick={() => handleClick(10)}
              >
                <span
                  onClick={handleClickd}
                  className=" flex items-center p-2 text-gray-900 rounded-lg :text-white  hover:bg-[#f7dedf] cursor-pointer "
                >
                  <TbLogout className="text-[17.5px]" />
                  <span className=" flex-1 ml-3 font-all whitespace-nowrap">
                    Logout
                  </span>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="md:hidden  lg:hidden sm:hidden block">
        <MobileBottomNavbar />
      </div>
    </>
  );
};

export default Sidebar;
