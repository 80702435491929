import React, { useContext } from "react";

import Messages from "./Messages";
import Input from "./Input";
import { ChatContext } from "../../../Context/ChatContext";

const Chat = () => {
  const { data } = useContext(ChatContext);

  return (
    // this is the header of the chat displaying the receiver image and name
    <div className="chats justify-start gap-2">
      {/* header of the current user / receiver  */}
      <div className="py-2 px-3  bg-white border-product_card w-[97%] mx-auto rounded-md flex flex-row justify-between items-center">
        <div className="flex items-center">
          <div>
            <img className="w-10 h-10 rounded-full" src={data.user?.photoURL} />
          </div>
        </div>
        <div className="ml-4 text-center">
          <p className="text-grey-darkest">{data.user?.displayName}</p>
        </div>
        <div className="flex">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
            >
              <path
                fill="#263238"
                fillOpacity=".5"
                d="M15.9 14.3H15l-.3-.3c1-1.1 1.6-2.7 1.6-4.3 0-3.7-3-6.7-6.7-6.7S3 6 3 9.7s3 6.7 6.7 6.7c1.6 0 3.2-.6 4.3-1.6l.3.3v.8l5.1 5.1 1.5-1.5-5-5.2zm-6.2 0c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6z"
              />
            </svg>
          </div>
        </div>
      </div>
      {/* Messages */}
      <div className="flex-1 overflow-auto bg-[#f5f5f5] h-[65vh] ">
        <div className="py-2 px-3">
          <div className="flex justify-center mb-2"></div>
          {/* encryption message  */}
          <div className="flex justify-center mb-4">
            <div
              className="rounded py-2 px-4"
              style={{ backgroundColor: "#FCF4CB" }}
            >
              <p className="text-xs">
                Messages to this chat and calls are now secured with end-to-end
                encryption. Tap for more info.
              </p>
            </div>
          </div>
          {/* encryption message sed  */}
          <Messages />
        </div>
      </div>
      <Input />
    </div>
  );
};

export default Chat;

{
  /* <Input /> */
}
