import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import GifComponent from "./GifComponent";
import Logo from "../../assets/logo/Logo-Trans.png";
import image from "../../assets/logo/auth.png";

export default function WelcomeAuth() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      {isLoading ? (
        <GifComponent />
      ) : (
        <div className="lg:overflow-hidden flex flex-col items-center justify-center min-h-screen bg-[#cb22290f]">
          <img src={Logo} alt="" className=" h-[49px] fade-in xs:w-auto" />

          <h1 className="text-center font-all font-semibold xs:text-xl text-[30px]	 my-2 leading-10 text-[#000000] mt-2 -in">
            Welcome to INO!
          </h1>

          <div className="relative top-[14px] drop-auth w-max bg-white rounded-[33px] mx-auto fade-in">
            <img src={image} alt="" className="mx-auto h-[19rem] w-full object-contain" />
          </div>

          <p className="font-all italic text-sm lg:text-base lg:w-[55rem] xs:w-full mx-auto mt-8 px-4 xs:px-7">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta corrupti inventore
            voluptatum molestias

          </p>

          <p className="font-all mt-5 text-center text-sm xs:text-xs px-4 xs:px-7">
            Tap 'Agree & Continue' to proceed after reviewing the INO{" "}
            <Link to="/auth/to" className="underline-offset-2 text-[#cb2229] cursor-pointer">
              Terms of Services
            </Link>
          </p>

          <Link to="/auth/verification">
            <button className="flex justify-center bg-[#CB2229]  text-white rounded-full font-all p-2 mt-4  w-48  transform hover:scale-105 duration-500 ease-in-out mx-auto">
              Agree and Continue
            </button>
          </Link>
        </div>
      )}
    </>
  );
}
