// import React from 'react'


// const ChatWindow = () => {
//   return (
//     <>
//     <div className='home'>
//       <div className="container">
//         <Sidebar/>
//         <Chat/>
//       </div>
//     </div>

// <div></div>

//     </>

//   )
// }

// export default ChatWindow


import Sidebar from '../Chats/ChatComponets/Sidebar'
import Chat from '../Chats/ChatComponets/Chat'
import React from "react";

function ChatWindow() {
  return (
    <>
      <div className="container mx-auto" style={{ marginTop: "-19px" }}>
        <div className="py-6 h-[88vh]">
          <div className="flex border border-grey rounded shadow-lg h-full ml-[15rem]">
            <div className="w-1/3 border flex flex-col">
              <Sidebar />
            </div>
            <div className="w-2/3 border flex flex-col mr-[2%]">
              <Chat />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatWindow;
